import { Badge } from '../../components/badge/badge'
import { PriceDropBadge } from './price-drop-badge/price-drop-badge'
import { getDealsText } from '../listing/get-deals-text'
import type { ListingCardPreview_ListingFragment } from './__generated__/listing-card-preview.gql'

type ListingCardPreviewBadgeProps = {
  className?: string
  listing: Partial<ListingCardPreview_ListingFragment>
}

export function DiscountBadge({
  className,
  listing,
}: ListingCardPreviewBadgeProps) {
  const dealsBadgeText = getDealsText(listing)

  return (
    <>
      {dealsBadgeText ? (
        <Badge
          data-tag_action="ignore"
          data-tid="deals_badge"
          text={dealsBadgeText}
          className={className}
        />
      ) : (
        !listing.isUnpaid &&
        listing.hasPriceDrops && (
          <PriceDropBadge className={className} listing={listing} />
        )
      )}
    </>
  )
}
